<template>
  <div
    id="app"
    class="app"
  >
    <header class="app__header">
      <main-menu
        with-finder
        finder-margin-up
      />
    </header>
    <main class="app__content">
      <div class="container content">
        <nuxt-page />
      </div>
    </main>

    <Footer />
  </div>
</template>

<script lang="ts">
import MainMenu from '@/core/components/menu/MainMenu.vue'
import Footer from '@/core/components/footer/Footer.vue'

export default defineNuxtComponent({
  components: {
    MainMenu,
    Footer,
  }
})
</script>

<style lang="scss" scoped>
.app {
  &__header {
    @apply bg-white;

    @screen lg {
      @apply bg-transparent;
      @apply transition-all duration-500;
    }
  }

  &__content {
    // @apply flex flex-col;
    @screen lg {

      @apply mt-4;
    }
  }
}

.content {
  @apply mx-auto;
  @screen lg {
    width: 1100px;
  }

  @screen sm {
    @apply mx-auto;
  }
}
</style>
